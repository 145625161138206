<template>
  <b-card v-if="data && data.length" no-body class="card-statistics">
    <b-card-header class="d-flex justify-content-between">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <multi-state-switch label="" :options="tss_options" v-model="applicationType" class="black-text"
          style="font-size: 18px" buttonClass="px-1 py-2" />
      </div>
      <div class="d-flex">
        <v-select v-model="selected_date.intake_month" :options="all_months" style="width: 150px" class="mr-1"
          placeholder="Month" @input="applicationStatusDate" />
        <v-select v-model="selected_date.intake_year" :options="getAllYears()" style="width: 150px" placeholder="Year"
          @input="applicationStatusDate" />
      </div>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col style="cursor: pointer" v-for="(item, index) in currentData" :key="index + 'as'" xl="3" sm="6"
          class="mb-2 mb-xl-0 pt-1 rounded" :style="status_id === item.status_id
            ? 'background-color: rgb(0, 143, 251); color: white;'
            : ''
            " @click="applicationStatusSelected(item)">
          <b-media no-body style="margin-bottom: 1rem">
            <b-media-body class="my-auto">
              <h4 :class="status_id === item.status_id
                ? 'mb-0 text-light'
                : 'font-weight-bolder mb-0'
                ">
                {{ item.count }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.status_name }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { all_months, getAllYears } from "@/assets/json_data/data";
import MultiStateSwitch from "@/views/components/MultiStateSwitch.vue";
import store from "@/store";

export default {
  data() {
    return {
      // currentData: [],
      selected_month: null,
      selected_year: null,
      all_months,
      applicationType: "applications",
      selected_date: {
        intake_month: null,
        intake_year: null,
      },
      tss_options: [
        {
          label: "Applications",
          value: "applications",
        },
        {
          label: "Visa",
          value: "visa",
        },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormSelect,
    vSelect,
    MultiStateSwitch,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    status_id: {
      type: Number,
      default: () => 0,
    },
  },

  computed: {
    currentData: function () {
      let currentData;

      if (this.applicationType == "visa") {
        currentData = this.data.filter((item) =>
          item.status_name.includes("Visa")
        );
      }
      if (this.applicationType == "applications") {
        currentData = this.data.filter(
          (item) => !item.status_name.includes("Visa")
        );
      }
      this.$emit("statusSelected", currentData[0]);

      return currentData;
    },
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },

  watch: {
    user_type: function () {
      if (this.user_type === "visa") {
        this.applicationType = "visa";
      }
    },
  },

  methods: {
    applicationStatusSelected(item) {
      this.$emit("statusSelected", item);
    },
    applicationStatusDate() {
      this.$emit("applicationStatusDate", this.selected_date);
    },
    getAllYears,
  },
};
</script>
