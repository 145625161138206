<template>
    <div>
        <b-col>
            <b-card>
                <div class="d-flex justify-content-between mb-2">
                    <h2>Students Applications</h2>
                    <v-select :options="appUsers" label="fullName" placeholder="Select Applications User"
                        v-model="application_user_id" :reduce="(item) => item.user_id" style="width: 500px;"
                        @input="onTouch"></v-select>
                </div>
                <b-table :fields="applications_fields" :items="applicationList" responsive
                    v-if="applicationData.length > 1">
                </b-table>
            </b-card>
        </b-col>
        <b-col>
            <b-card>
                <div class="d-flex justify-content-between mb-2">
                    <h2>Students Visa</h2>
                    <v-select :options="visaUsers" label="fullName" placeholder="Select Visa User"
                        v-model="visa_user_id" :reduce="(item) => item.user_id" style="width: 500px;"
                        @input="onVisa"></v-select>
                </div>
                <b-table :fields="visa_fields" :items="visaList" responsive v-if="visaItems.length > 1">
                </b-table>
            </b-card>
        </b-col>
    </div>

</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BTable,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
import CommonServices from "@/apiServices/CommonServices";
import OperationsManagerServices from "@/apiServices/OperationsManagerServices";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BTable,
        vSelect,
    },
    data() {
        return {
            users: [],
            applicationData: [],
            visaItems: [],
            application_user_id: null,
            visa_user_id: null,
            applications_fields: [
                {
                    key: "status_name",
                    label: 'Report Status'
                },
                {
                    key: "franchise_count",
                    label: 'Franchise'
                },
                {
                    key: "associate_count",
                    label: 'Associate'
                },
                {
                    key: "direct_count",
                    label: 'Direct'
                },
                {
                    key: "total",
                    label: 'Total',
                    variant: 'secondary'
                },
            ],
            visa_fields: [
                {
                    key: "status_name",
                    label: 'Report Status'
                },
                {
                    key: "associate_count",
                    label: 'Associate'
                },
                {
                    key: "direct_count",
                    label: 'Direct'
                },
                {
                    key: "total",
                    label: 'Total',
                    variant: 'secondary'
                },
            ]
        };
    },
    computed: {
        user_type() {
            return store.getters["user/getUserDetails"].user_type;
        },
        appUsers() {
            const users = this.users.filter(user =>
                ['Applications'].includes(user.user_type_name)
            );
            return users
        },
        visaUsers() {
            const users = this.users.filter(user =>
                ['Visa'].includes(user.user_type_name)
            );
            return users
        },
        applicationList() {
            const data = [...this.applicationData];
            if (data.length) {
                data[data.length - 1]._rowVariant = 'secondary';
            }
            return data;
        },
        visaList() {
            const data = [...this.visaItems];
            if (data.length) {
                data[data.length - 1]._rowVariant = 'secondary';
            }
            return data;
        },
    },
    methods: {
        async getUsers() {
            const res = await CommonServices.getAllUsers();
            this.users = res.data.data
        },
        async getData() {
            const payload = {
                application_user_id: this.application_user_id
            };
            const res = await OperationsManagerServices.getStudentsByApplicationStatus(payload);
            this.applicationData = res.data.data;
        },
        async getVisaList() {
            const payload = {
                visa_user_id: this.visa_user_id
            };
            const res = await OperationsManagerServices.getStudentsByVisaStatus(payload);
            this.visaItems = res.data.data;
        },
        onTouch() {

            this.getData();

        },
        onVisa() {

            this.getVisaList()
        },
    },
    beforeMount() {
        this.getUsers();
        this.getData()
        this.getVisaList()

    }
}
</script>

<style></style>
