var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[(_vm.user_type === 'counselor')?_c('b-col',[_c('b-card',{staticClass:"card-app-design assigned-staff-card"},[_c('h4',[_vm._v("Student Registration Link")]),_c('b-button',{staticClass:"btn btn-sm btn-outline-primary mr-1",on:{"click":function($event){return _vm.openStudentLink()}}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Click Here")])])],1)],1):_vm._e(),(_vm.user_type === 'operations_manager' )?_c('b-col',[_c('ManagerTable')],1):_vm._e(),(_vm.user_type === 'counselor')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Filters"}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Lead Type"}},[_c('v-select',{attrs:{"placeholder":"Lead Type","options":_vm.leadTypeOptions,"clearable":true,"item-text":"label","reduce":function (item) { return item.value; }},on:{"input":function () {
                    _vm.handleLeadSourceOptions();
                    _vm.getHomeData();
                  }},model:{value:(_vm.leadType),callback:function ($$v) {_vm.leadType=$$v},expression:"leadType"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Lead Source"}},[_c('v-select',{attrs:{"placeholder":"Lead Source","disabled":_vm.leadType ? false : true,"options":_vm.leadSourceOptions,"clearable":true,"item-text":"label","reduce":function (item) { return item.value; }},on:{"input":_vm.onSelectLeadSource},model:{value:(_vm.leadSource),callback:function ($$v) {_vm.leadSource=$$v},expression:"leadSource"}})],1)],1)],1)],1)],1):_vm._e(),(
        (_vm.leadType == 'franchise' && _vm.leadSource) || _vm.user_type === 'franchise'
      )?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":(_vm.user_type !== 'franchise' ? 'Franchise ' : '') + 'Filters'}},[(_vm.showAllLevelToggle())?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('p',{staticClass:"mr-1"},[_vm._v(" Show All Level Data "),_c('small',[_vm._v(_vm._s(_vm.getStudentSupportDescription()))])]),_c('b-form-checkbox',{attrs:{"switch":"","inline":""},on:{"input":_vm.onShowAllLevelData},model:{value:(_vm.showAllLevelData),callback:function ($$v) {_vm.showAllLevelData=$$v},expression:"showAllLevelData"}})],1),_c('hr')]):_vm._e(),_c('b-row',[(_vm.user_details.franchise_level === 'master' || _vm.master_f_id)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('p',{staticStyle:{"margin-bottom":"5px"}},[_vm._v(" Area Franchise "),_c('small',{staticStyle:{"color":"#aeaeae"}},[_vm._v("(Select to get Micro Franchise)")])]),_c('v-select',{attrs:{"placeholder":"Area Franchise","options":_vm.area_franchises,"clearable":true,"label":"user_name","reduce":function (item) { return item.user_id; }},on:{"input":_vm.onSelectAreaFranchise},model:{value:(_vm.area_f_id),callback:function ($$v) {_vm.area_f_id=$$v},expression:"area_f_id"}})],1)],1):_vm._e(),(_vm.area_f_id || _vm.user_details.franchise_level === 'area')?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Micro Franchise"}},[_c('v-select',{attrs:{"placeholder":"Micro Franchise","options":_vm.micro_franchises,"clearable":true,"label":"user_name","reduce":function (item) { return item.user_id; }},on:{"input":_vm.onSelectMicroFranchise},model:{value:(_vm.micro_f_id),callback:function ($$v) {_vm.micro_f_id=$$v},expression:"micro_f_id"}})],1)],1):_vm._e()],1),_c('hr'),_c('b-row',[(
              (_vm.leadType === 'franchise' && _vm.leadSource) ||
              _vm.user_type === 'franchise'
            )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('p',{staticStyle:{"margin-bottom":"5px"}},[_vm._v(" Student Support "),_c('small',{staticStyle:{"color":"#aeaeae"}},[_vm._v(_vm._s(_vm.getStudentSupportDescription()))])]),_c('v-select',{attrs:{"placeholder":"Student Support","options":_vm.ss_options,"clearable":true,"label":"user_name","reduce":function (item) { return item.user_id; }},on:{"input":_vm.onSelectStudentSupport},model:{value:(_vm.ss_id),callback:function ($$v) {_vm.ss_id=$$v},expression:"ss_id"}})],1)],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.ss_id || _vm.area_f_id || _vm.micro_f_id || _vm.master_f_id)?_c('b-col',{attrs:{"cols":"12"}},[_c('agent-students',{attrs:{"show_all_level_data":_vm.showAllLevelData,"lead":_vm.getLeadData,"agent_user_id":_vm.ss_id
            ? _vm.ss_id
            : _vm.micro_f_id
            ? _vm.micro_f_id
            : _vm.area_f_id
            ? _vm.area_f_id
            : _vm.master_f_id}})],1):_vm._e(),(_vm.user_type === 'counselor' && _vm.quiz_responses)?_c('b-col',{attrs:{"cols":"12"}},[_c('quiz-responses',{attrs:{"quiz_responses":_vm.quiz_responses}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('pyramid-chart',{attrs:{"data":_vm.pyramidChartData}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('counts',{attrs:{"data":_vm.studentCounts,"header":"Students"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('e-chart',{attrs:{"series":_vm.echartData.series,"xAxisData":_vm.echartData.xAxisData},on:{"echartDateRange":_vm.getEchartDateRange}})],1)],1),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('donut-chart',{attrs:{"series":_vm.applicationsStatus,"labels":_vm.applicationsStatusLabels}})],1),_c('b-col',{attrs:{"cols":"7"}},[_c('applications-statuses',{attrs:{"status_id":_vm.selectedStatusId,"data":_vm.applicationStatusCount,"status":_vm.selectedStatus},on:{"statusSelected":_vm.applicationStatusSelected,"applicationStatusDate":_vm.getApplicationStatusDate}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('applications-wise-students',{attrs:{"lead":_vm.getLeadData,"agent_user_id":_vm.micro_f_id ? _vm.micro_f_id : _vm.area_f_id,"status_id":_vm.selectedStatusId,"status":_vm.selectedStatus,"applicationStatusDate":_vm.applicationStatusDate}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }